import axios from 'axios';
import '../util/config'
import serverInfo from '../util/globals';

export async function checkrigamovimento(dataoperazione, entrata, uscita, descrizione, banca, idpalestra, email) {
  const formData = new FormData();
  formData.append('dataoperazione', dataoperazione?dataoperazione:'');
  formData.append('entrata', entrata?entrata:0);
  formData.append('uscita', uscita?uscita:0);
  formData.append('descrizione', descrizione?descrizione:'');
  formData.append('banca', banca?banca:'');
  formData.append('palestra', idpalestra);
  formData.append('website', 'annotatio.online');
  formData.append('email',email);

  const response = await axios.post(
    global.config.server+'/checkmovimento',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}

export async function confermainviorighe(idpalestra, email, righe, token) {

  const formData = new FormData();
  
  formData.append('palestra', idpalestra);
  formData.append('website', 'annotatio.online');
  formData.append('email',email);
  formData.append('righe',JSON.stringify(righe));

  const response = await axios.post(
    serverInfo.namesec+'/conto/uploadrighemovimento',
    formData,
	  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}