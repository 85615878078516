import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import serverInfo from "../util/globals";
import { useSelector } from "react-redux";

export default function SelectTipoMovimento (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
	
	const [listaTipo, setlistaTipo] = useState([]);
	const [selezionato, setselezionato] = useState(props.selected);
		
	 useEffect(() =>{
		 if (props.listaTipo){
			setlistaTipo(props.listaTipo)
		 }
		 else{
			if (palestra&&palestra.palestra){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.palestra.id);
		  axios.post(serverInfo.namesec+"/conto/getTipologieWp",formData,
		  {
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+token
		  },
		  },)
		  .then(response=>{
			  setlistaTipo(response.data);
		  })
		  .catch(err => {
			alert("tipologie: "+err.response.data.message);
		});
	}
	  }
	},[palestra, email]);

	    return (
	    	<div className="col">
			<label className="control-label">Tipologia</label>
			<select className="form-control" onChange={props.update} name="tipo" value={props.selected?props.selected:""}>
				<option key={-1} value="">-- nessun tipo --</option>
				{
					listaTipo?
					listaTipo.map((item,index) =>(
					<option key={index} value={item.codice} >{item.descrizione}</option>
					)
					)
					:null
	    		}
			</select>
			</div>
	    );
	}