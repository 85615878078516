import React, { useState } from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faSave} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function Tipologia (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [id, setid]=useState(props.selected.id)
	const [codice, setcodice]=useState(props.selected.codice);
	const [descrizione, setdescrizione]=useState(props.selected.descrizione);
	const [entrataUscita, setentrataUscita]=useState(props.selected.entrataUscita);
	const [previsioni,setprevisioni]=useState(props.selected.previsioni);
	const [totale, settotale]=useState(props.selected.totale);
	const [visible, setvisible]=useState(false);
	const [messaggio, setmessaggio]=useState(false);

	function handleSubmit(event) {
		  event.preventDefault();
		const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	formData.append("id",id)
	formData.append('codice', codice);
	formData.append('descrizione',descrizione);
	formData.append('entrataUscita', entrataUscita);
	formData.append('previsioni', previsioni);
	formData.append('totale', totale);
	formData.append('tipocosto', '');
		
    axios.post(serverInfo.namesec+'/conto/salvaTipoMov/'+codice, 
    		formData,
			{
			headers: {
			  Accept: 'application/json',
			  'Content-Type': 'multipart/form-data',
			  'Access-Control-Allow-Origin': '*',
			  'Access-Control-Allow-Methods': 'POST',
			  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			  'Access-Control-Allow-Credentials': 'true',
			  'Authorization' :'Bearer '+token
			},
			},
    )
      .then((result) => {
      setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
        //props.refresh(new Date().getTime());
        setvisible(false);
      });
  }

	function elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la tipologia selezionata?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
			formData.append('palestra',palestra.palestra.id);
			formData.append('lic','000');
			  axios.post(serverInfo.namesec+'/conto/eliminaTipoMovimento/'+id, formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			  },
			  },)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        props.refresh(new Date().getTime());
			          
			          setvisible(false);
			        });
			}
	  }

		var style1={
				color:"white"
		}
		
	    return (
	    	<form method="POST">
	    		<div className="row">
					<div className="col">
						<label>Codice</label>
						<input name="codice" type="text" maxLength="50" value={
							codice} readOnly={true} />
					</div>
					<div className="col">	
						<label>Descrizione</label>
						<input maxLength="50" name="descrizione" type="text" value={descrizione} onChange={(e) => setdescrizione(e.target.value)} />
					</div>
					<div className="col">	
						<label>Entrata/Uscita</label>
						<select name="entrataUscita" onChange={(e) => setentrataUscita(e.target.value)} value={entrataUscita}>
							<option value="E">Entrata</option>
							<option value="U">Uscita</option>
							<option value="EU">Entrata e Uscita</option>
						</select>
					</div>
					<div className="col">	
						<label>Da contare nel totale</label>
						<input type="checkbox" name="totale" onChange={(e) =>{settotale(e.target.checked)} } checked={totale} />
													</div>
					<div className="col">	
						<label>Da considerare nelle previsioni
						<input type="checkbox" name="totale" onChange={(e) =>{setprevisioni(e.target.checked)} } checked={previsioni} />
						</label>
						</div>
					
					
							<div className="col">
			  				<a type="button" className="btn btn-primary" onClick={handleSubmit} style={style1}>
							  <FontAwesomeIcon icon={faSave} />
							  </a>
			  				</div>

					<div className="col">
						<a className="btn btn-danger" style={style1} onClick={elimina}>
						<FontAwesomeIcon icon={faTrash} />
						</a>
					</div>
						
				</div>
				<hr style={{borderTop: '8px solid #bbb', borderRadius: '5px', marginBottom:'25px'}} />
				</form>
	    );
	}