import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import Menu from "../toolbar/menu";
import DettaglioAndamento from "./DettaglioAndamento";
import NuovoAndamento from "./NuovoAndamento";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function Andamento () {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const token = useSelector(state => state.gestoreUtente.token);

    const [previsioni, setprevisioni]=useState([]);
    const [popupVisible,setpopupVisible]=useState(false);
   const [listaTipo, setlistaTipo] = useState([]);
   const [datada, setdatada] = useState();
   const [dataa, setdataa] = useState();
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
   function togglePopup(){
      setpopupVisible(!popupVisible)
    }
    
    useEffect(() =>{
      if (palestra){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.palestra.id);

          axios.post(serverInfo.namesec+"/conto/eseguiPrevisioni", formData,
          {
          headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
          },
        },
          )
          .then(response=>{
              setprevisioni(response.data);
              
                axios.post(serverInfo.name+"/getTipologieWp",formData,
                {
                headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization' :'Bearer '+token
                },
              },)
                .then(response2=>{
                    setlistaTipo(response2.data);
                });
          });
        }
      },[palestra, email]);
    
      function refresh(){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('palestra',palestra.palestra.id);
        formData.append('lic','000');
          axios.post(serverInfo.namesec+"/conto/eseguiPrevisioni", formData,
          {
          headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
          },
        },)
          .then(response=>{
            setprevisioni(response.data);
          });
      }

      function eseguiprevisioni(){
        if (!(datada&&datada!=='')){
          alert("Data inizio obbligatoria")
          return
        }
        if (!(dataa&&dataa!=='')){
          alert("Data fine obbligatoria")
          return
        }
        console.log('Esegui previsioni: '+datada+' '+dataa)
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('palestra',palestra.palestra.id);
        formData.append('datada',datada);
        formData.append('dataa',dataa);
          axios.post(serverInfo.namesec+"/conto/eseguiPrevisioni", formData,
          {
          headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
          },
        },)
          .then(response=>{
            console.log(response.data)
            setprevisioni(response.data);
            setkeytorefresh(new Date().getTime())
          });
      }
    
            return (
            <div key={keytorefresh} className="container">
              <label>Data inizio
                <input type="date" name="datada" value={datada} onChange={(e) => setdatada(e.target.value) } />
              </label>
              <label>Data fine
                <input type="date" name="dataa" value={dataa} onChange={(e) => setdataa(e.target.value) } />
              </label>
              <button className="btn" onClick={eseguiprevisioni}>Cerca previsioni</button>
                {popupVisible?
                  <div className="row">
                    <NuovoAndamento listaTipo={listaTipo} refresh={refresh} togglePopup={togglePopup}/>
                  </div>
                  :null}
                <div className="row">
                
                  <a type="button" className="btn btn-info btn-lg" onClick={togglePopup}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  </a>
                </div>
{
    previsioni&&Array.isArray(previsioni)&&previsioni.length>0?
    previsioni.map(previsione=> (
        <DettaglioAndamento previsione={previsione} refresh={refresh} />
    ))
  :"Nessuna previsione trovata"

		}
        </div>
 );
}