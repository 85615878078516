import React, { useState } from "react";
import {navigate} from 'hookrouter'
import { useSelector } from "react-redux";
import Intestazione from "./Intestazione";

export default function Menu (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    return (
      <nav className="navbar sticky-top navbar-expand-lg navbar-light " style={{backgroundColor: "#e3f2fd"}}>
        <Intestazione />
        
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav">
        <li className="nav-item">
          <a className={"nav-link "+(props.active==='movimenti'?'active':'')} onClick={()=> navigate('/annotatio-conto-ric/',true)}>Movimenti</a>
          </li>
					<li  className="nav-item">
            <a className={"nav-link "+(props.active==='banche'?'active':'')} onClick={()=> navigate('/annotatio-conto-banks/',true)} >Banche</a>
            </li>
          <li className="nav-item"><a className={"nav-link "+(props.active==='tipo'?'active':'')} onClick={()=> navigate('/annotatio-conto-tipo/',true)}>Tipologie</a></li>
          <li className="nav-item"><a className={"nav-link "+(props.active==='tracciati'?'active':'')} onClick={()=> navigate('/annotatio-conto-trax/',true)}>Tracciati</a></li>
          <li className="nav-item"><a className={"nav-link "+(props.active==='regole'?'active':'')} onClick={()=> navigate('/annotatio-conto-rules/',true)}>Regole</a></li>
          <li className="nav-item"><a className={"nav-link "+(props.active==='caricamenti'?'active':'')} onClick={()=> navigate('/annotatio-conto-loads/',true)}>Caricamenti</a></li>
          <li className="nav-item"><a className={"nav-link "+(props.active==='previsioni'?'active':'')} onClick={()=> navigate('/annotatio-conto-preview/',true)}>Previsioni</a></li>
          </ul>
          </div>
          <a className="navbar-brand" href="#">Annotatio-CONTO</a>
        </nav>
    );
}