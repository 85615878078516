import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function ListaBancheSelect (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const token = useSelector(state => state.gestoreUtente.token);

  const [banche, setbanche] = useState([])
  
  useEffect(() => {
    if (palestra&&palestra.palestra){
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra.palestra.id);
	  axios.post(serverInfo.namesec+"/conto/getBanche",formData,
	  {
		headers: {
		  Accept: 'application/json',
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': 'POST',
		  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		  'Access-Control-Allow-Credentials': 'true',
		  'Authorization' :'Bearer '+token
		},
	  },)
	  .then(response=>{
      setbanche(response.data);
      console.log(response.data)
	  }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
  }
  },[palestra, email]);

        return (
          <span>
              <label>Banca</label>
                <select className="form-control" value={props.banca} onChange={props.update} name="banca">
                    <option key={-1} value="">--seleziona una banca--</option>
                {
                    banche.map((item,index) =>(
                            <option key={index} value={item.nome}>
                                {item.descrizione}
                                </option>
                            )
                    )
                }
                </select>
                </span>
        );
}