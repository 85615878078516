import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function SelectTipologieEntrata (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [listaTipo, setlisattipo] = useState([])
		    	
	 
	 useEffect(() => {
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('palestra',palestra.palestra.id);
		formData.append('lic','000');
		  axios.post(serverInfo.namesec+"/conto/getTipologieEntrataRest",formData,
		  {
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+token
		  },
		  },)
		  .then(response=>{
			  setlisattipo(response.data);
		  });
	  },[palestra,email]);

	    return (
	    	<span>
			<label className="control-label">Tipologia Entrata</label>
			<select className="form-control" onChange={(e) => props.update(e.target.value)} name="tipo" value={props.selected?props.selected:""}>
				<option key={-1} value="">-- nessun tipo --</option>
				{
					listaTipo.map((item,index) =>(
					<option key={index} value={item.codice} >{item.descrizione}</option>
					)
	    			)
	    		}
			</select>
			</span>
	    );
	}