import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faSave} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";
import { eseguiregola } from "../DAO/regoleDAO";

class Regola extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		regola: this.props.selected.regola,
	    		tipo: this.props.selected.codtipomovimento,
	    		visible: false
	    	}

	    this.update = this.update.bind(this);
	    this.updateTipo = this.updateTipo.bind(this);
	    this.elimina = this.elimina.bind(this);

	    this.eseguiregola = this.eseguiregola.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	  }

	  eseguiregola(){
		async function esegui(idregola,email, idpalestra){
			const result=await eseguiregola(idregola,email, idpalestra)
			alert(result)
		}
		esegui(this.props.selected.id,this.props.email, this.props.palestra.id);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	
	updateTipo(tipoSelezionato) {
		var tipo = {...this.state.tipo}
		tipo = tipoSelezionato;
		this.setState({tipo, visible: true})
	  }
	
	  handleSubmit(event) {
		  event.preventDefault();
	    const { id,regola,tipo } = this.state;

	    const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	    formData.append('id', this.props.selected.id);
	    formData.append('descr',regola);
		formData.append('codtipo', tipo);
		
      axios.post(serverInfo.namesec+'/conto/modificaRegola',formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+this.props.token
	  },
	  },  )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.props.refresh();
          this.setState({ visible: false });
        });
    }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la regola selezionata?')){
			const { id } = this.state;
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
			formData.append('id', this.props.selected.id);
			  axios.post(serverInfo.namesec+'/conto/eliminaRegola', formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          
			          this.setState({ visible: false });
			        });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
		
	    return (
	    	<form method="POST">
	    		<div className="row">
							<div className="col">
								<label className="control-label">Regola</label>
								<input name="regola" className="form-control" type="text" onChange={this.update} maxLength="50" value={this.state.regola} />
							</div>
							<SelectTipoMovimento updateTipo={this.updateTipo} selected={this.state.tipo} />
							{this.state.visible?
							<div className="col">
			  				<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>
							  <FontAwesomeIcon icon={faSave} />
							  </a>
			  				</div>
			  				:null}
							<div className="col">
							<a className="btn btn-danger" style={style1} onClick={this.elimina}>
							<FontAwesomeIcon icon={faTrash} />
							</a>
							</div>
							<div className="col">
							<a className="btn btn-danger" style={style1} onClick={this.eseguiregola}>
							Esegui regola
							</a>
							</div>
					
					<hr />
				</div>
				</form>
	    );
	  }
	}



	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata,
			token: state.gestoreUtente.token
		}
	}
	
	export default connect(mapStateToProps, null)(Regola)