import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faSave} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class Banca extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		nome: this.props.selected.nome,
	    		descrizione: this.props.selected.descrizione,
	    		visible: false
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	handleSubmit(event) {
		  event.preventDefault();
	    const { id,nome,descrizione,idutente } = this.state;
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	    formData.append('id', id);
	    formData.append('nome',nome);
		formData.append('descrizione', descrizione);
		
    axios.post(serverInfo.namesec+'/conto/salvaBanca/'+id, 
    		formData,
			{
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			},
    )
      .then((result) => {
      this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
        this.props.refresh();
        this.setState({ visible: false });
      });
  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la banca selezionata?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('palestra',this.props.palestra.id);
			formData.append('lic','000');
			formData.append('id', this.state.id);
			  axios.post(serverInfo.namesec+'/conto/eliminaBanca/'+this.state.id,formData,
			{
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			},)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh(new Date().getTime());
			          
			          this.setState({ visible: false });
			        });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
		
	    return (
	    	<form method="POST">
	    		<div className="row">
						<input name="id" type="hidden"  value={this.state.id}  />
					<div className="col">
						<label>Nome</label>
						<input name="nome" type="text" maxLength="50" value={this.state.nome} onChange={this.update} />
					</div>
					<div className="col">	
						<label>Descrizione</label>
						<input maxLength="50" name="descrizione" type="text" value={this.state.descrizione} onChange={this.update} />
					</div>
					{this.state.visible?
							<div className="col">
			  				<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>
							  <FontAwesomeIcon icon={faSave} />
							  </a>
			  				</div>
			  			:null}
					<div className="col">
						<a className="btn btn-danger" style={style1} onClick={this.elimina}>
						<FontAwesomeIcon icon={faTrash} />
						</a>
					</div>
						
				</div>
					<hr />
				</form>
	    );
	  }
	}


const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token: state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(Banca)