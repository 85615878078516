import {createSlice} from '@reduxjs/toolkit';

export const gestoreProtocolliSlice = createSlice({
  name: 'gestoreProtocolli',
  initialState: {
    protocolloselezionato:{},
    protocollicercati:[]
  },
  reducers: {
    setprotocolloselezionato: (state, proto) => {
      state.protocolloselezionato = proto.payload;
    },
    setprotocollicercati: (state, proto) => {
      state.protocollicercati = proto.payload;
    },
  },
});

export const {setprotocolloselezionato, setprotocollicercati} = gestoreProtocolliSlice.actions; 

export default gestoreProtocolliSlice.reducer;