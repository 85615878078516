import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import NuovoTracciato from "./nuovoTracciato";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import Tracciato from "./tracciato";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function Tracciati () {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

  const [tracciati, settracciati] = useState([]);
  const [popupVisible, setpopupVisible] = useState(false);
  
	function togglePopup(){
  setpopupVisible(!popupVisible)
  }
  
  useEffect(() =>{
	if (palestra&&palestra.palestra){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getConfigList", formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	  },)
	  .then(response=>{
		
		  settracciati(response.data);
		  console.log(response);
	  })
	}
  },[palestra, email]);

  function refresh(){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getConfigList", formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	  },)
	  .then(response=>{
		  settracciati(response.data);
		  console.log(response);
	  });
  }

    return (
    		<div className="container">
				{popupVisible?
				<div className="row">
    				<NuovoTracciato email={email} refresh={refresh} togglePopup={togglePopup}/>
				</div>
				:null}
			<a type="button" className="btn btn-info btn-lg" onClick={togglePopup}>
						<FontAwesomeIcon icon={faPlusCircle} />
					</a>
    		{
    			
    			tracciati.map(item =>(
    					<Tracciato email={email} key={item.id} tracciato={item} refresh={refresh}  />
    					)
    			)
    		}
    		</div>
    );

}