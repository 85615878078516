import axios from 'axios';
import '../util/config'

export async function eseguiregola(idregola,email, idpalestra) {
  const formData = new FormData();
  formData.append('idregola', idregola);
  formData.append('palestra', idpalestra);
  formData.append('website', 'annotatio.online');
  formData.append('email',email);

  const response = await axios.post(
    global.config.server+'/eseguiregola',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}