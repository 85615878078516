import React from "react";
import axios from 'axios';
import '../util/config'
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'

import Swal from "sweetalert2";
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class NuovoAndamento extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 

      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.update = this.update.bind(this);
    }
    
    componentDidMount(){
        this.setState({tipo:''});
        this.setState({periododa:''});
        this.setState({periodoa:''});
        this.setState({previsto:0});
      }
    
      handleSubmit(event) {
        event.preventDefault();
      const { tipo,periododa,periodoa,previsto } = this.state;
      const formData = new FormData();
      formData.append('website', global.config.domain);
      formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
      formData.append('id', 0);
      formData.append('codTipologia',tipo);
      formData.append('periododa', periododa);
      formData.append('periodoa', periodoa);
      formData.append('previsto', previsto);
      
  axios.post(serverInfo.namesec+'/conto/salvaPrevisione', 
          formData,
          {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+this.props.token
          },
          },
  )
    .then((result) => {
    this.setState({messaggio:result.data});
      const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
          Toast.fire({
            type: 'success',
            title: result.data
          })
      this.props.refresh();
      this.setState({ visible: false });
    });
}

      update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }  
    
      componentDidCatch(error, info) {
            // You can also log the error to an error reporting service
            console.log(error, info);
          }

    render() {
        return (
      <div className="modal-header">
        <h5 className="modal-title" id="nuovoandamentoLabel">Nuovo tracciato</h5>

      <div className="modal-body">
            <SelectTipoMovimento selected={this.state.tipo} update={this.update} listaTipo={this.props.listaTipo}/>
            Da <input type="date" name="periododa" value={this.state.periododa} onChange={this.update}/>
            A <input type="date" name="periodoa" value={this.state.periodoa} onChange={this.update}/>
            Importo previsto <input type="number" name="previsto" value={this.state.previsto} onChange={this.update} tep=".01" />
            <button type="button" className="btn btn-secondary" onClick={this.props.togglePopup} >Close</button>
            <a type="button" className="btn btn-primary" onClick={this.handleSubmit} >Salva modifiche</a>
        </div>
        </div>
                );
            }
          }

          const mapStateToProps = state => {
            return {
              palestra: state.gestoreUtente.palestraassociata.palestra,
              email: state.gestoreUtente.emailloggato,
              idanagselezionata: state.gestoreUtente.idanagselezionata,
              token: state.gestoreUtente.token

            }
          }
          
          export default connect(mapStateToProps, null)(NuovoAndamento)