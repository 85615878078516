import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class NuovaRegola extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: 0,
	    		regola: null,
	    		tipo: null,
	    		visible: false
	    	}
		this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovaRegola=this.showNuovaRegola.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
		handleSubmit(event) {
		  event.preventDefault();
	    const { id,regola,tipo } = this.state;
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	    formData.append('descr',regola);
		formData.append('codtipo', tipo);
      axios.post(serverInfo.namesec+'/conto/inserisciRegola', 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.props.refresh(new Date().getTime());
          this.setState({ visible: false });
        });
    }
	

	showNuovaRegola(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		return (
			
			
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Inserimento Nuova Regola</h5>

      <div className="modal-body">
		
		
		<form method="POST">
			<div className="col">
				<div className="row">
						<input type="hidden" name="id" value={this.state.id}/>

							<div className="form-group">
								<label className="control-label">Regola</label>
								<input className="form-control" name="regola" type="text" maxLength="50" onChange={this.update} value={this.state.regola} />
							</div>
							<SelectTipoMovimento update={this.update} selected={this.state.tipo} />
				</div>
			</div>
			</form>
			</div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={this.props.togglePopup} >Close</button>
        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Save changes</button>
      </div>
    </div>

	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token: state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(NuovaRegola)