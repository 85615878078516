import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import ListaBancheSelect from "../banche/listaBancheSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faSave,faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class Tracciato extends React.Component {
	constructor(props) {
        super(props);
        this.state = { 
            id:this.props.tracciato.id,
            banca:this.props.tracciato.nomeBanca,
            inizio:this.props.tracciato.rigaInizio,
            entrate:this.props.tracciato.colonnaEntrate,
            uscite:this.props.tracciato.colonnaUscite,
            descrizione:this.props.tracciato.colonnaDescr,
            causale:this.props.tracciato.colonnaCausale,
            dataop:this.props.tracciato.colonnaDataOperazione,
            dataval:this.props.tracciato.colonnaDataValuta,
            causfissa:this.props.tracciato.causaleFissa,
			notInTotal:this.props.tracciato.notInTotal,
			descrtracciato:this.props.tracciato.descrtracciato
        };
        this.update = this.update.bind(this);
        this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovaTipologia=this.showNuovaTipologia.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
		handleSubmit(event) {
		  event.preventDefault();
	    const { id,banca,inizio,entrate,uscite,descrizione, causale, dataop, dataval, causfissa, notInTotal, descrtracciato} = this.state;
	    const formData = new FormData();
        formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
        formData.append('id',this.props.tracciato.id);
        formData.append('nomebanca',banca);
        formData.append('inizio',inizio);
        formData.append('entrate',entrate);
        formData.append('uscite',uscite);
        formData.append('descrizione',descrizione);
        formData.append('causale',causale);
        formData.append('dataop',dataop);
        formData.append('dataval',dataval);
        formData.append('causfissa',causfissa);
		formData.append('inTotale',notInTotal);
		formData.append('descrtracciato',descrtracciato&&descrtracciato!==''?descrtracciato:banca);
      axios.post(serverInfo.namesec+'/conto/salvaConfig', 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.props.refresh();
          this.setState({ visible: false });
        });
    }
    
    componentDidMount(){
        this.setState(this.props.tracciato)
    }

	showNuovaTipologia(){
		this.setState({ visible: !this.state.visible });
	}
    
     elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il tracciato selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('palestra',this.props.palestra.id);
			formData.append('lic','000');
            formData.append('id',this.props.tracciato.id);
			  axios.post(serverInfo.namesec+'/conto/eliminaConfig', formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          
			          this.setState({ visible: false });
			        });
			}
	  }

	render() {
		return (
				<span>

			<form className="form-row align-items-center" action="salvaConfig" method="post" name="Configurazione File banca" id="formconfig">
						<div className="form-group">
							<label>Descrizione tracciato</label>
							<input className="form-control" name="descrtracciato" type="text" value={this.state.descrtracciato} onChange={this.update} />
						</div>
						<div className="form-group">
                            <ListaBancheSelect banca={this.state.banca} update={this.update} />
						</div>
						<div className="form-group">
							<label>Riga Inizio</label>
							<input className="form-control" name="inizio" type="text" value={this.state.inizio} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Entrate</label>
							<input className="form-control" name="entrate" type="text" value={this.state.entrate} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Uscite</label>
							<input className="form-control" name="uscite" type="text" value={this.state.uscite} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Descrizione</label>
							<input className="form-control" name="descrizione" type="text" value={this.state.descrizione} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Causale</label>
							<input className="form-control" name="causale" type="text" value={this.state.causale} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Data Operazione</label>
							<input className="form-control" name="dataop" type="text" value={this.state.dataop} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Data Valuta</label>
							<input className="form-control" name="dataval" type="text" value={this.state.dataval} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Causale fissa</label>
							<input className="form-control" maxlength="50" size="30" name="causfissa" type="text" value={this.state.causfissa} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Non considerare nel totale</label>
							<input className="form-control" checked={this.state.notInTotal} name="inTotale" type="checkbox" value={this.state.notInTotal} onChange={(e) => e.target.checked?this.setState({notInTotal:1}):this.setState({notInTotal:0})} />
						</div>
			</form>
		    <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>
			<FontAwesomeIcon icon={faSave} />
			</button>
            <a className="btn btn-danger" onClick={this.elimina}>
			<FontAwesomeIcon icon={faTrash} />
			</a>
			</span>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token: state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(Tracciato)