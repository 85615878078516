import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import serverInfo from "../util/globals";
import { useSelector } from "react-redux";

export default function ListaCausaliSelect (props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const token = useSelector(state => state.gestoreUtente.token);

    const [causali, setcausali] = useState([]);
    
    useEffect(() =>{
        if(palestra&&palestra.palestra){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.palestra.id);
        axios.post(serverInfo.namesec+"/conto/getCausaliRest", formData,
        {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
        },
      },)
        .then(response=>{
            setcausali(response.data);
        }).catch(err => {
			alert(err.response.data.message);
		});
    }
    },[palestra, email])

      return (
          <span>
              <label>Causali </label>
              <select onChange={props.update} name="causale" value={props.causale}>
                  <option key={-1} value="">--seleziona una causale--</option>
              {
                  causali.map((item,index) =>(
                          <option key={index} value={item}>
                              {item}
                              </option>
                          )
                  )
              }
              </select>
              </span>
      );
  }