import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class NuovaTipologia extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		codice: 0,
	    		descrizione: null,
	    		entrataUscita: 'U',
				previsioni:null,
				totale:null,
				visible: true
	    	}
		this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovaTipologia=this.showNuovaTipologia.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
		handleSubmit(event) {
		  event.preventDefault();
	    const { codice,descrizione,entrataUscita,previsioni,totale } = this.state;
	    const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	    formData.append('codice', codice);
	    formData.append('descrizione',descrizione);
		formData.append('entrataUscita', entrataUscita);
		formData.append('previsioni', previsioni);
		formData.append('totale', totale);
		formData.append('tipocosto','');
      axios.post(serverInfo.namesec+'/conto/salvaTipoMov/'+codice, 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.props.refresh(new Date().getTime());
          this.setState({ visible: false });
        });
    }
	

	showNuovaTipologia(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		return (
				
					      <div className="modal-header">
					        <h5 className="modal-title" id="nuovatipologiaMovLabel">Inserimento Nuova Tipologia Movimento</h5>
					        
					      <div className="modal-body">
					
							<form method="POST">
								<div className="col">
									<div className="row">
									<div className="form-group">
											<label>Codice</label>
											<input name="codice" className="form-control" type="text" maxLength="50" value={this.state.codice} onChange={this.update} />
										</div>
										<div className="form-group">
											<label>Descrizione</label>
											<input maxLength="50" className="form-control" name="descrizione" onChange={this.update} type="text" value={this.state.descrizione} />
										</div>
										<div className="form-group">
											<label>Entrata/Uscita</label>
											<select name="entrataUscita" className="form-control" onChange={this.update} value={this.state.entrataUscita}>
												<option value="E">Entrata</option>
												<option value="U">Uscita</option>
												<option value="EU">Entrata e Uscita</option>
											</select>
										</div>
										<div className="form-group">
											<label>Da contare nel totale</label>
											<label>SI <input type="radio" className="form-control" name="totale" value={true} onChange={this.update} checked={this.state.totale==1||this.state.totale=='true'}/></label> -
											<label>NO <input type="radio" className="form-control" name="totale" value={false} onChange={this.update} checked={this.state.totale==0||this.state.totale=='false'}/></label> -
										</div>
										<div className="form-group">
											<label>Da considerare nelle previsioni</label>
											<label>SI <input type="radio" className="form-control" name="previsioni" value={true} onChange={this.update} checked={this.state.previsioni==1||this.state.previsioni=='true'} /></label> -
											<label>NO <input type="radio" className="form-control" name="previsioni" value={false} onChange={this.update} checked={this.state.previsioni==0||this.state.previsioni=='false'} /></label>
										</div>
									</div>
									</div>
							</form>
			</div>
		      <div className="modal-footer">
		        <button type="button" className="btn btn-secondary" onClick={this.props.togglePopup} >Close</button>
		        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Save changes</button>
		      </div>
		    </div>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token : state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(NuovaTipologia)