import React, { useState } from "react";
import MovimentoInput from './movimentoInput'
import '../util/config'
import App from "./App";
import { useSelector } from "react-redux";

export default function ListaMovimenti (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
	
	const [listaMovimenti,setlistaMovimenti]=useState(props.movimenti)    		
	const [listaTipoEntrata,setlistaTipoEntrata]=useState([])    	
	const [listaTipoUscita,setlistaTipoUscita]=useState([])
	var totEntrate=0
	var totNonCalcolato = (0)
	var totUscite=(0)
	const [differenza,setdifferenza]=useState(0)
	var entrateDettaglio=([]) 
	var usciteDettaglio = ([])	
	var gruppiEntrate=([])
	var gruppiUscite = ([])

	var totIvaEntrata=(0)
	var totIvaUscita = (0)

	const [kettorefresh, setkeytorefrsh] = useState(new Date().getTime());
	
	function updateSommaAlTotale(e){
		const movIndex=props.movimenti.findIndex(p=>{
			return p.id===e.id;
		}
		);
		const movToModify=props.movimenti[movIndex];
		if (movToModify){
			movToModify.notInTotal=e.notInTotal?e.notInTotal:false;
		
			const movimenti=[...props.movimenti];
			movimenti[movIndex]=movToModify;
			setlistaMovimenti(movimenti);
		}
	}

	function refresh(){
		setkeytorefrsh(new Date().getTime());
	}
	
	function calcolaTotali(){
		entrateDettaglio =([]);
		usciteDettaglio = ([]);
		gruppiEntrate = ([]);
		gruppiUscite=([]);
		totEntrate =(0);
		totIvaEntrata=(0);
		totNonCalcolato = (0);
		totUscite = (0);
		totIvaUscita = (0);

		var labelE=[]
		var valuesE=[]
		var coloriE=[]
		var sfondiE=[]
		var dataEntrate=[]
		var labelU=[]
		var valuesU=[]
		var coloriU=[]
		var sfondiU=[]
		var dataUscite=[]

    	const listaMov=props.movimenti.map((item,index) =>{
    		if(item.notInTotal===false){
				if (parseFloat(item.entrata)>0){

					labelE.push(item.descrizione)
					valuesE.push(parseFloat(item.entrata))
					coloriE.push('rgba(0, '+index+', '+Math.floor(255/(index+1))+', 0.2)')
					sfondiE.push('rgba(0, '+index+', '+Math.floor(255/(index+1))+', 1)')

					totEntrate+=(!item.notInTotal?parseFloat(item.entrata):0);
					totIvaEntrata+=(!item.notInTotal?parseFloat(item.ivaEntrata?item.ivaEntrata:0):0);
					entrateDettaglio.push({"name":item.descrizione,"value":item.entrata});
					var i;
					var trovato=false;
					if (gruppiEntrate){
						for (i=0;i<gruppiEntrate.length;i++){
							if (gruppiEntrate[i].name===item.tipoMovimento1){
								gruppiEntrate[i].value=gruppiEntrate[i].value+(item.entrata);
								trovato=true;
							}
						}
					}
					if (!trovato){
						gruppiEntrate.push({"name":item.tipoMovimento1,"value":(item.entrata)});
					}
				}
				else if(parseFloat(item.uscita)<0){
					labelU.push(item.descrizione)
					  valuesU.push(parseFloat(-1*item.uscita))
					  coloriU.push('rgba('+Math.floor(255/(index+1))+', '+index+', 0, 0.2)')
					  sfondiU.push('rgba('+Math.floor(255/(index+1))+', '+index+', 0, 1)')

						totUscite+=(!item.notInTotal?parseFloat(item.uscita):0);
						totIvaUscita+=(!item.notInTotal?parseFloat(item.ivaUscita?item.ivaUscita:0):0);
						usciteDettaglio.push({"name":item.descrizione,"value":(item.uscita*-1)});
					var i;
					var trovato=false;
					if (gruppiUscite){
						for (i=0;i<gruppiUscite.length;i++){
							if (gruppiUscite[i].name===item.tipoMovimento1){
								gruppiUscite[i].value=gruppiUscite[i].value+(item.uscita*-1);
								trovato=true;
							}
						}
					}
					if (!trovato){
						gruppiUscite.push({"name":item.tipoMovimento1,"value":(item.uscita*-1)});
					}
					
				}
				dataEntrate ={
					//labels: labelE,
					datasets: [
					  {
						label: 'Entrate',
						data: valuesE,
						backgroundColor: coloriE,
						borderColor: sfondiE,
						borderWidth: 1,
					  },
					],
				  };
				dataUscite={
					//labels: labelU,
					datasets: [
					  {
						label: 'Uscite',
						data: valuesU,
						backgroundColor: coloriU,
						borderColor: sfondiU,
						borderWidth: 1,
					  },
					],
				  };
    		}
			else{

				totNonCalcolato+=(item.uscita*-1)+parseFloat(item.entrata);
			}
    	}
		);

	}
	
		calcolaTotali();
		
    	const listaMov=props.movimenti.map(item =>{	
					return <MovimentoInput key={item.id} selected={item} 
					refresh={refresh} 
					listaTipoUscita={listaTipoUscita} 
					listaTipoEntrata={listaTipoEntrata} 
					calcolaTotali={calcolaTotali} 
					updateSommaAlTotale={updateSommaAlTotale} 
					protocolli={props.protocolli}/>
    			}
    		
			)
			const diff=totEntrate+totUscite
			const diffIva=totIvaEntrata+totIvaUscita
    	
    	
	    return (
	    		<div>
					{listaMov}
					<App movimenti={props.movimenti} />
		    		
		    		<div className="row">
						<div className="col-1">TOTALI</div>
						<div className="col-3">Entrate {totEntrate.toFixed(2)}</div>
						<div className="col-3">Uscite {totUscite.toFixed(2)}</div>
						<div className="col-3">NETTO {diff}</div>
						<div className="col-1">{diff>0?"BUONO :)":"MALE! :("}</div>
					</div>
					<div className="row">
						<div className="col-1">DIFFERENZE</div>
						<div className="col-3">Iva a debito {totIvaEntrata.toFixed(2)}</div>
						<div className="col-3">Iva a credito {totIvaUscita.toFixed(2)}</div>
						<div className="col-3">IVA {diffIva>0?"a debito "+diffIva:"a credito" +diffIva}</div>
						<div className="col-1">Non calcolato {totNonCalcolato.toFixed(2)}</div>
		    		</div>

	    		<div className="row">
	    		<div className="app">
	            <div className="row">
	              <div className="mixed-chart">
	             
	              </div>
	            </div>
	            
	          </div>
	    		</div>
				
				
		</div>
	    );
}