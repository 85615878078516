import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import ListaBancheSelect from "../banche/listaBancheSelect";
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class NuovoTracciato extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		nomebanca: '',
	    		inizio: null,
	    		entrate: null,
				uscite:null,
				descrizione:null,
                causale: null,
                dataop:null,
                datava:null,
                causfissa:'',
                inTotale:null,
				descrtracciato:''
	    	}
		this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovaTipologia=this.showNuovaTipologia.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
		handleSubmit(event) {
		  event.preventDefault();
	    const { banca,inizio,entrate,uscite,descrizione, causale, dataop, dataval, causfissa, inTotale} = this.state;
	    const formData = new FormData();
        formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
        formData.append('id',0);
        formData.append('nomebanca',banca);
        formData.append('inizio',inizio);
        formData.append('entrate',entrate);
        formData.append('uscite',uscite);
        formData.append('descrizione',descrizione);
        formData.append('causale',causale);
        formData.append('dataop',dataop);
        formData.append('dataval',dataval);
        formData.append('causfissa',causfissa);
        formData.append('inTotale',inTotale);
		formData.append('descrtracciato',this.state.descrtracciato);

      axios.post(serverInfo.namesec+'/conto/salvaConfig', 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+this.props.token
			  },
			  },
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.props.refresh();
          this.setState({ visible: false });
        });
    }
	

	showNuovaTipologia(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		return (
      <div className="modal-header">
        <h5 className="modal-title" id="nuovotracciatoMovLabel">Inserimento nuova configurazione</h5>

      <div className="modal-body">
      
			<form action="salvaConfig" method="post" name="Configurazione File banca" id="formconfig">
      			<div className="col">
					<div className="row">
					<div className="form-group">
							<label>Descrizione tracciato</label>
							<input className="form-control" name="descrtracciato" type="text" value={this.state.descrtracciato} onChange={this.update}/>
						</div>
						<div className="form-group">
                        <ListaBancheSelect update={this.update} />
						</div>
						<div className="form-group">
							<label>Riga Inizio</label>
							<input className="form-control" maxlength="2" size="3" name="inizio" type="text" value={this.state.inizio} onChange={this.update}/>
						</div>
						<div className="form-group">
							<label>Col. Entrate</label>
							<input className="form-control" maxlength="2" size="3" name="entrate" type="text" value={this.state.entrate} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Uscite</label>
							<input className="form-control" maxlength="2" size="3" name="uscite" type="text" value={this.state.uscite} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Descrizione</label>
							<input className="form-control" maxlength="2" size="3" name="descrizione" type="text" value={this.state.descrizione} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Causale</label>
							<input className="form-control" maxlength="2" size="3" name="causale" type="text" value={this.state.causale} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Data Operazione</label>
							<input className="form-control" maxlength="2" size="3" name="dataop" type="text" value={this.state.dataop} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Col. Data Valuta</label>
							<input className="form-control" maxlength="2" size="3" name="dataval" type="text" value={this.state.dataval} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Causale fissa</label>
							<input className="form-control" maxlength="50" size="30" name="causfissa" type="text" value={this.state.causfissa} onChange={this.update} />
						</div>
						<div className="form-group">
							<label>Da considerare nel totale</label>
							<input className="form-control" checked="checked" name="inTotale" type="checkbox" value={this.state.inTotale} onChange={this.update} />
						</div>
					</div>
				</div>
			</form>
			</div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={this.props.togglePopup} >Close</button>
		<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Save</button>
	  </div>
	</div>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token: state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(NuovoTracciato)