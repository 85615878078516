import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import serverInfo from "../util/globals";
import { useSelector } from "react-redux";

export default function TracciatiSelect (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [tracciati, settracciati] = useState([]);
  
  useEffect(() =>{
	if (palestra&&palestra.palestra){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getConfigList", formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	  },)
	  .then(response=>{
		 settracciati(response.data);
		  console.log(response);
	  });
	}
  },[palestra, email])

    return (
		<select onChange={(e) => {console.log(e.target.selectedIndex); console.log(tracciati[e.target.selectedIndex-1]); props.update(e.target.value); props.settracciato(tracciati[e.target.selectedIndex-1])}} name="tracciato" value={props.idtracciato}>
			<option key={-1} value="">--seleziona un tracciato--</option>
		{
			tracciati&&Array.isArray(tracciati)?tracciati.map((item,index) =>(
					<option key={index} value={item.id}>
						{item.descrtracciato?item.descrtracciato:item.nomeBanca} 
						</option>
					)
			)
			:null
		}
		</select>
    );

}