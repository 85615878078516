import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import TracciatiSelect from "../tracciati/tracciatiSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUpload} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import {OutTable, ExcelRenderer} from 'react-excel-renderer'
import { checkrigamovimento, confermainviorighe } from "../DAO/movimentiDAO";
import serverInfo from "../util/globals";

export default function Caricamenti () {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [caricamenti, setcaricamenti] =useState([]);
	const [idtracciato, setidtracciato] = useState('');
	const [tracciato, settracciato] = useState();
    const [selectedFile, setselectedFile] = useState();
	const [messaggio,setmessaggio] = useState('');
	const [visible, setvisible]= useState(false);
	const [rows, setrows] = useState([]);
    const [cols, setcols] = useState([]);
	const [righe, setrighe] = useState([])
	const [righeOK, setrigheOK] = useState([])
	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

	var righepostcheck=[]
	var righepostcheckOK=[]
	var filename=""

	const fileHandler = (event) => {
        setrighe([])
        let fileObj = event.target.files[0];
		console.log(fileObj)
		filename=fileObj.name
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
              setcols(resp.cols);
              setrows(resp.rows);
          }
        });               
    
      }

	function confermainvio(){
		async function conferma(){
			const result=await confermainviorighe(palestra.palestra.id, email, righeOK, token);
			alert(result)
			
		}
		conferma()
	}
	  

  useEffect(() =>{
	// if (palestra&&palestra.palestra){
	// const formData = new FormData();
	// formData.append('website', global.config.domain);
	// formData.append('email',email);
	// formData.append('palestra',palestra.palestra.id);
	// formData.append('lic','000');
	//   axios.post(serverInfo.namesec+"/conto/getCaricamenti", formData,
	//   {
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'multipart/form-data',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'POST',
    //     'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    //     'Access-Control-Allow-Credentials': 'true',
    //     'Authorization' :'Bearer '+token
    //   },
    // },)
	//   .then(response=>{
	// 	setcaricamenti(response.data);
	//   });
	// }
  },[palestra, email, keytorefresh]);

  function checkriga(riga){
	async function checktessera(dataoperazione, entrata, uscita, descrizione, banca, causalefissa){
		const result=await checkrigamovimento(dataoperazione, entrata, uscita, descrizione, banca, palestra.palestra.id, email);
		//console.log("result "+result)
		const rigachecked={
			risultatocheck: result.risultatocheck, 
			dataoperazione: dataoperazione, 
			datavaluta: datavaluta, 
			entrata: entrata, 
			uscita: uscita, 
			descrizione: descrizione, 
			causalefissa: causalefissa, 
			causale: causale, 
			descrtracciato: tracciato.descrtracciato, 
			nomebanca: banca,
		nomefile: filename}
		righepostcheck.push(rigachecked)
		if (result.risultatocheck.indexOf('OK')===0){
			righepostcheckOK.push(rigachecked)
		}
		console.log(rigachecked)
		setkeytorefresh(new Date().getTime())
		
	}
	//alert(tracciato.colonnaDataOperazione)
	let datetemp=null;
	let dataoperazione="";
	if(riga[tracciato.colonnaDataOperazione-1]){
		if (riga[tracciato.colonnaDataOperazione-1].length===10){
			dataoperazione=riga[tracciato.colonnaDataOperazione-1]
		}
		else{
			datetemp=new Date(Date.UTC(0, 0, riga[tracciato.colonnaDataOperazione-1] - 1));
			dataoperazione=("0" + datetemp.getDate()).slice(-2) + '/' + (("0" + (datetemp.getMonth() + 1)).slice(-2))+'/'+datetemp.getFullYear()
		}
	}
	console.log('riga[tracciato.colonnaDataOperazione-1] '+riga[tracciato.colonnaDataOperazione-1])
	let colonnaentrate;
	let colonnauscite;
	if (tracciato.colonnaEntrate>0){
		colonnaentrate=tracciato.colonnaEntrate;
	}
	else{
		colonnaentrate=-1*(tracciato.colonnaEntrate);
	}

	if (tracciato.colonnaUscite>0){
		colonnauscite=tracciato.colonnaUscite;
	}
	else{
		colonnauscite=-1*(tracciato.colonnaUscite);
	}

	let entrata=0;
	if (riga[colonnaentrate-1]){
		if (tracciato.colonnaEntrate>0){
			if (riga[colonnaentrate-1]>=0){
				entrata=riga[colonnaentrate-1]
			}
		}
		else{
			if (riga[colonnaentrate-1]<=0){
				entrata=-1*riga[colonnaentrate-1]
			}
		}
	}

	let uscita=0;
	if (riga[colonnauscite-1]){
		if (tracciato.colonnaUscite>0){
			if (riga[colonnauscite-1]>=0){
				uscita=-1*riga[colonnauscite-1]
			}
		}
		else{
			if (riga[colonnauscite-1]<=0){
				uscita=riga[colonnauscite-1]
			}
		}
	}

	let descrizione=''
	let coldescr=tracciato.colonnaDescr.split('+')
	for (let i=0;i<coldescr.length;i++){
		descrizione+=riga[coldescr[i]-1]?riga[coldescr[i]-1]:''
		descrizione+=" - "
	}
	
	let banca=tracciato.nomeBanca

	let datavaluta="";
	if(riga[tracciato.colonnaDataValuta-1]){
		if (riga[tracciato.colonnaDataValuta-1].length===10){
			datavaluta=riga[tracciato.colonnaDataValuta-1]
		}
		else{
			datetemp=new Date(Date.UTC(0, 0, riga[tracciato.colonnaDataValuta-1] - 1));
			datavaluta=("0" + datetemp.getDate()).slice(-2) + '/' + (("0" + (datetemp.getMonth() + 1)).slice(-2))+'/'+datetemp.getFullYear()
		}
	}
	console.log('riga[tracciato.colonnaDataValuta-1] '+riga[tracciato.colonnaDataValuta-1])
	let causalefissa=tracciato.causaleFissa
	console.log('causale fissa: '+causalefissa)
	let causale=riga[tracciato.colonnaCausale-1]
	checktessera(dataoperazione, entrata, uscita, descrizione, banca, causalefissa)
	}

  function invia(){
	if (!tracciato||!tracciato.id){
		alert('selezionare un tracciato')
		return;
	}
	if (!rows||rows.length===0){
		alert('selezionare un file')
		return;
	}
	let rowstemp=rows;
	
	//console.log(rowstemp)
	righepostcheck=[]
	righepostcheckOK=[]
	for (let i=0; i<rowstemp.length; i++){
		(checkriga(rowstemp[i]));
	} 
	 setrighe(righepostcheck)
	 setrigheOK(righepostcheckOK)
	 setkeytorefresh(new Date().getTime())
}	

    return (
    		<div key={keytorefresh} className="container">
				<div className="row">
					<fieldset>
						<legend>Carica nuova lista di movimenti da file</legend>
							<div className="row">
							<input type="file" onChange={(event) => fileHandler(event)} style={{"padding":"10px"}} />
            				{(!righe||righe.length===0)&& <OutTable data={rows} columns={cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" /> }

							{righe&&righe.length>0&&
							<span>
								Da caricare {righeOK.length} righe
								<table className="table-bordered">
									<tr>
										<th>Risultato</th>
										<th>Data op.</th>
										<th>Data valuta</th>
										<th>Entrata</th>
										<th>Uscita</th>
										<th>Descrizione</th>
										<th>Causale fissa (come da tracciato)</th>
										<th>Causale (come da file)</th>
										<th>Descrizione tracciato</th>
										<th>Banca</th>
									</tr>
								{
								righe.map(riga => 
									<tr style={{backgroundColor:riga.risultatocheck.indexOf('OK')===0?'#aaffaa':'#ffaaaa'}}>
										<td>{riga.risultatocheck}</td>
										<td>{riga.dataoperazione}</td>
										<td>{riga.datavaluta}</td>
										<td style={{backgroundColor:riga[2]>0?'green':''}}>{riga.entrata}</td>
										<td style={{backgroundColor:riga[3]<0?'orange':''}}>{riga.uscita}</td>
										<td>{riga.descrizione}</td>
										<td>{riga.causalefissa}</td>
										<td>{riga.causale}</td>
										<td>{riga.descrtracciato}</td>
										<td>{riga.nomebanca}</td>
									</tr>
								)
								}
								</table>
								<button onClick={confermainvio}>Conferma Invio solo gli OK</button>
								</span>
							}
							</div>
							{/* <div>
								<button onClick={invia}>Invia per controllo</button>
							</div> */}
							<div className="row">
								<TracciatiSelect email={email} idtracciato={idtracciato} update={setidtracciato} settracciato={settracciato} />
								<button className="btn btn-success" onClick={invia}>
									<FontAwesomeIcon icon={faUpload} />
								</button>
							</div>
					</fieldset>
					</div>
					{/* <div className="row">
						<div className="row">
							<h3>Caricamenti precedenti</h3>
						</div>
					{
						caricamenti.map(item =>(
								<Caricamento key={item.id} selected={item}
								onUpdate={item.id} refresh={setkeytorefresh}  />
								)
						)
					}
					</div> */}
    		</div>
    );
}