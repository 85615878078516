import axios from 'axios';
import '../util/config'
import serverInfo from '../util/globals';

export async function cercaprotocolligenerico(idpalestra, stringaproto) {
  const formData = new FormData();
  formData.append('idpalestra', idpalestra);
  formData.append('term', stringaproto);
  const response = await axios.post(
    serverInfo.namesec+'/proto/cercaProtocolliGenerico',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}