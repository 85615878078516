import React, { useEffect, useState } from "react";
import '../util/config'
import PalestraAssociataSelect from "./PalestraAssociataSelect";
import { useSelector } from "react-redux";

export default function PalestreAssociateSelect (props) {
  const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
  const palestreassociate = useSelector(state => state.gestoreUtente.palestreassociate);

  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

      return (
          <span key={keytorefresh}>
              {palestreassociate?
              palestreassociate.map(palestraSocio =>(
               <PalestraAssociataSelect key={palestraSocio.id} soc={palestraSocio} link={props.link}
               setkeytorefresh={setkeytorefresh} />
              )
              )
              :"Nessuna attivit&agrave; commerciale associata"
            }
            </span>
        );
  }