import React from "react";
import ListaBanche from "../banche/listaBanche";
import Caricamenti from "../caricamenti/Caricamenti";
import FiltriMovimenti from "../movimenti/filtriMovimenti";
import Andamento from "../previsioni/Andamento";
import ListaRegole from "../regole/listaRegole";
import Tipologie from "../tipologie/listaTipologie";
import Tracciati from "../tracciati/tracciati";

export const routes = {
    // "*/annotatio-conto/": () => <FiltriMovimenti />,
    "*/annotatio-conto/": () => <FiltriMovimenti />,
    "*/annotatio-conto-ric/": () => <FiltriMovimenti />,
    "*/annotatio-conto-banks/": () => <ListaBanche />,
    "*/annotatio-conto-tipo/": () => <Tipologie />,
    "*/annotatio-conto-trax/": () => <Tracciati />,
    "*/annotatio-conto-rules/": () => <ListaRegole />,
    "*/annotatio-conto-loads/": () => <Caricamenti />,
    "*/annotatio-conto-preview/": () => <Andamento />,    
  };