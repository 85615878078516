import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import NuovaTipologia from "./nuovaTipologia";
import Tipologia from "./tipologia";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function Tipologie () {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

  const [tipologie, settipologie]=useState([])
  const [popupVisible, setpopupVisible]=useState(false)
	const [keytorefresh, setkeytorefresh]=useState(new Date().getTime())

	function togglePopup(){
  		setpopupVisible(!popupVisible)
  	}
  
  useEffect(() =>{
	if (palestra&&palestra.palestra){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('lic','000');
	formData.append('palestra',palestra.palestra.id);
	  axios.post(serverInfo.namesec+"/conto/getTipologie", formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	  },)
	  .then(response=>{
		  settipologie(response.data);
		  console.log(response);
	  }).catch(err => {
		alert(err.response.data.message);
	});
}
  },[palestra, email, keytorefresh])
  
    return (
    		<div key={keytorefresh} className="container">
				{popupVisible?
				<div className="row">
    		<NuovaTipologia refresh={setkeytorefresh} togglePopup={togglePopup}/>
				</div>
				:null}
			<a type="button" className="btn btn-info btn-lg" onClick={togglePopup}>
						<FontAwesomeIcon icon={faPlusCircle} />
					</a>
    		{
    			tipologie.map(item =>(
    					<Tipologia key={item.codice} selected={item}
    					onUpdate={item.codice} refresh={setkeytorefresh}  />
    					)
    			)
    		}
    		</div>
    );
}

