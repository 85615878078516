import React from "react";
import axios from 'axios';
import '../util/config'
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faSave} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

import Swal from "sweetalert2";

class DettaglioAndamento extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 

      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.update = this.update.bind(this);
      this.elimina = this.elimina.bind(this);
    }
    
    componentDidMount(){
        this.setState({tipo:this.props.previsione.codicetipologia});
        this.setState({periododa:this.props.previsione.periododa});
        this.setState({periodoa:this.props.previsione.periodoa});
        this.setState({previsto:this.props.previsione.importoprevisto});
        this.setState({id:this.props.previsione.id});
        this.setState({reale:this.props.previsione.importoreale});
      }
    
handleSubmit(event) {
        event.preventDefault();
      const { id,tipo,periododa, periodoa, previsto } = this.state;
      const formData = new FormData();
      formData.append('website', global.config.domain);
      formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
      formData.append('id', id);
      formData.append('codTipologia', tipo);
      formData.append('periododa',periododa);
      formData.append('periodoa',periodoa);
      formData.append('previsto',previsto);
      
    axios.post(serverInfo.namesec+'/conto/salvaPrevisione', formData,
    {
    headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization' :'Bearer '+this.props.token
    },
  },)
    .then((result) => {
    this.setState({messaggio:result.data});
      const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
          Toast.fire({
            type: 'success',
            title: result.data
          })
      this.props.refresh();
      this.setState({ visible: false });
    });
}

elimina(event) {
    event.preventDefault();
  const { id } = this.state;
  const formData = new FormData();
  formData.append('website', global.config.domain);
  formData.append('email',this.props.email);
  formData.append('palestra',this.props.palestra.id);
  formData.append('lic','000');
  formData.append('id', id);
  
axios.post(serverInfo.namesec+'/conto/eliminaPrevisione', formData,
  {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization' :'Bearer '+this.props.token
  },
  },)
.then((result) => {
this.setState({messaggio:result.data});
  const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });
      Toast.fire({
        type: 'success',
        title: result.data
      })
  this.props.refresh();
  this.setState({ visible: false });
});
}

      update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }  
    
      componentDidCatch(error, info) {
            // You can also log the error to an error reporting service
            console.log(error, info);
          }

    render() {
        return (
		    <div className="row">
            <SelectTipoMovimento selected={this.state.tipo} update={this.update} listaTipo={this.props.listaTipo}/>
            <div className="col-2">
            Da <input type="date" name="periododa" value={this.state.periododa} onChange={this.update}/>
            </div>
            <div className="col-2">
            A <input type="date" name="periodoa" value={this.state.periodoa} onChange={this.update}/>
            </div>
            <div className="col-2">
            Importo previsto <input type="number" name="previsto" value={this.state.previsto} step=".01" onChange={this.update}/>
            </div>
            <div className="col-2">
            Importo reale <input type="number" disabled={true} readOnly={true} value={this.state.reale} />
            </div>
            <div className="col-1">
            <a type="button" className="btn btn-primary" onClick={this.handleSubmit} >
            <FontAwesomeIcon icon={faSave} />
            </a>
            </div>
            <div className="col-1">
            <a className="btn btn-danger" onClick={this.elimina}>
            <FontAwesomeIcon icon={faTrash} />
            </a>
            </div>
           
        </div>
                );
            }
          }

const mapStateToProps = state => {
  return {
    palestra: state.gestoreUtente.palestraassociata.palestra,
    email: state.gestoreUtente.emailloggato,
    idanagselezionata: state.gestoreUtente.idanagselezionata,
    token: state.gestoreUtente.token
  }
}

export default connect(mapStateToProps, null)(DettaglioAndamento)