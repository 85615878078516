import React from "react";

export default function TipologiaCostoSelect (props) {

		return (
			
			<select name="tipocosto" value={props.selected?props.selected:""} onChange={props.update}>
				<option key="-1" value="">-- tipologia di costo --</option>
				<option key="fisso" value={"fisso"}>Fisso</option>
				<option key="variabile" value={"variabile"}>Variabile</option>
				<option key="ammBeniMobiliStrumentali" value={"ammBeniMobiliStrumentali"}>Beni mobili strumentali</option>
				<option key="ammBeniImmobiliStrumentali" value={"ammBeniImmobiliStrumentali"}>Beni immobili strumentali</option>
				<option key="ammBeniImmaterialiStrumentali" value={"ammBeniImmaterialiStrumentali"}>Beni immateriali strumentali</option>
			</select>
	);
}