import React from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser
} from 'devextreme-react/pivot-grid';

class App extends React.Component {

  componentDidMount() {
    this._pivotGrid.bindChart(this._chart, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false
    });
    this.getDatasource = this.getDatasource.bind(this);
  }

  getDatasource(){
    return new PivotGridDataSource({
        fields: [{
            caption: 'Entrata/Uscita',
            width: 120,
            dataField: 'entrataUscita',
            area: 'row',
            sortBySummaryField: 'Total'
          },{
          caption: 'Causale',
          width: 120,
          dataField: 'causale',
          area: 'row'
        }, {
          caption: 'Tipo movimento',
          dataField: 'tipoMovimento1',
          width: 150,
          area: 'row'
        },
        {
            caption: 'Dettaglio',
            dataField: 'id',
            width: 150,
            area: 'row',
            selector: function(data) {
                return `${data.dataValuta } (${ data.descrizione })`;
              }
          } , {
          dataField: 'dataValuta',
          dataType: 'date',
          area: 'column'
        }, {
          groupName: 'date',
          groupInterval: 'month',
          visible: true
        }, {
          caption: 'Totale',
          dataField: 'amount',
          dataType: 'number',
          summaryType: 'sum',
          format: 'currency',
          area: 'data'
          
        }],
        store: this.props.movimenti
      });
      
      
  }

  render() {
    return (
      <React.Fragment>
        <Chart ref={(ref) => ref?this._chart = ref.instance:null}>
          <Size height={200} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <CommonSeriesSettings type="bar" />
          <AdaptiveLayout width={450} />
        </Chart>

        <PivotGrid
          id="pivotgrid"
          dataSource={this.getDatasource()}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          ref={(ref) => ref?this._pivotGrid = ref.instance:null}
        >
          <FieldChooser enabled={true} height={400} />
        </PivotGrid>
      </React.Fragment>
    );
  }
}

const currencyFormatter = new Intl.NumberFormat(
    'en-US', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    }
  );
function customizeTooltip(args) {
  const valueText = currencyFormatter.format(args.originalValue);
  return {
    html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`
  };
}

export default App;
