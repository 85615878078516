import { useRoutes, A } from "hookrouter";
import LoadMainThings from "./util/LoadMainThings";
import Menu from "./toolbar/menu";
import { routes } from "./util/routes";
import Intestazione from "./toolbar/Intestazione";
import { useEffect } from "react";

const showDebugInfo=localStorage.getItem('debug')?true:false

function AppRoute(props) {
    const routeResult = useRoutes(routes);

  return (
    <div className="App">
      {props.intestazione&&<Intestazione/>}
      {props.showtoolbar&&<Menu />}
      <LoadMainThings toolbar={showDebugInfo} showDebugInfo={showDebugInfo} 
      email={props.email} nome={props.nome} 
      cognome={props.cognome} 
      telefono={props.telefono} 
      idselezionata={props.id} 
      t={props.t} />
      {routeResult}
    </div>
  );
}

export default AppRoute;
