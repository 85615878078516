import React from "react";
import axios from 'axios';
import SelectTipologieEntrata from '../tipologie/selectTipologieEntrata'
import SelectTipologieUscita from '../tipologie/SelectTipologieUscita'
import ListaProtocolliSelect from '../protocollo/listaProtocolliSelect'
import '../util/config';
import Swal from "sweetalert2";
import ListaBancheSelect from "../banche/listaBancheSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave, faTrash} from '@fortawesome/free-solid-svg-icons'
import TipologiaCostoSelect from "../bilancio/TipologiaCostoSelect";
import { useSelector, useDispatch } from "react-redux";
import serverInfo from "../util/globals";
import { useState } from "react";
import { useEffect } from "react";
import { setprotocolloselezionato } from "../STORE/reducers/gestoreProtocolli";
import DettaglioProtocollo from "../protocollo/dettaglioProtocollo";

export default function MovimentoInput(props) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
	const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);	
	const [id, setid] = useState(props.selected.id)
	const [dataOperazione, setdataOperazione] = useState(props.selected.dataOperazione)
	const [dataValuta, setdataValuta] = useState(props.selected.dataValuta)
	const [entrata, setentrata] = useState(props.selected.entrata)
	const [uscita, setuscita] = useState(props.selected.uscita)
	const [descrizione, setdescrizione] = useState(props.selected.descrizione)
	const [causale, setcausale] = useState(props.selected.causale)
	const [banca, setbanca] = useState(props.selected.nomeBanca)
	const [tipoMovimento1, settipoMovimento1] = useState(props.selected.tipoMovimento1)
	
	const [notInTotal, setnotInTotal] = useState(props.selected.notInTotal)
	const [note, setnote] = useState(props.selected.note)
	const [ivaEntrata, setivaEntrata] = useState(props.selected.ivaEntrata)
	const [ivaUscita, setivaUscita] = useState(props.selected.ivaUscita)
	const [tipocosto, settipocosto] = useState(props.selected.tipocosto)
	const [visible, setvisible] = useState( false)
	const [protocollo, setprotocollo] = useState(null)
	const [protocolli, setprotocolli] = useState([])
	const [messaggio, setmessaggio] = useState('')
	const [showproto, setshowproto] = useState(false)

	const [file, setfile] = useState(null)

	const dispatch = useDispatch()


	function updateNotInTotal(e){
		  if (e.target.checked){
		setnotInTotal(false);
		  }
		  else{
			setnotInTotal(true);
		  }
	  }

	  useEffect(() =>{
		handleMostraProtocolli()
	  },[])

	function handleMostraProtocolli(){
	    const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
        axios.post(serverInfo.namesec+'/conto/caricaProtoMovimentoRest/'+id, 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+window.$t
			  },
			},
      )
        .then((result) => {
        	setprotocolli(result.data);
        });
	  }
	
	function updateTipo(tipoSelezionato) {
		var tipoMovimento1t = tipoMovimento1
		tipoMovimento1t = tipoSelezionato;
		settipoMovimento1(tipoMovimento1t)
		setvisible(true)
	  }
	
	function updateProto(protoSelezionato) {
		var protocollo1 = protocollo
		protocollo1 = protoSelezionato;

		setprotocollo(protocollo1)
	  }

	  function handleInsertProtocollo() {
		if (protocollo){
	    const formData = new FormData();
	    formData.append('idmovimento', id);
		formData.append('idprotocollo',protocollo)
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/conto/salvaProtoContoRest', formData,
		  {
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+token
		  },
		}, )
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'bottom-end',
			  showConfirmButton: false,
			  timer: 10000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data.nomefile
			})
		  handleMostraProtocolli();
		});
	}
	}
	
	function handleEliminaProtocollo(idproto) {
		if (!window.confirm('Sei sicuro di voler elimiare il protocollo dal movimento selezionato?')){
			return
		}
		const formData = new FormData();
		
		formData.append('idmovimento', id);
		formData.append('idprotocollo',idproto)
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/conto/eliminaProtoConto', 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			  },
			},)
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'bottom-end',
			  showConfirmButton: false,
			  timer: 10000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
		  setvisible(false)
		  setfile(null);
		  handleMostraProtocolli();
        });
    }

	function showprotocol(proto){
		dispatch(setprotocolloselezionato(proto))
		setshowproto(!showproto)
	}
	
	function handleSubmit() {
	    const formData = new FormData();
	    formData.append('id', id);
	    formData.append('note', note?note:'');
		formData.append('tipoMovimento1', tipoMovimento1);
		formData.append('ivaEntrata',ivaEntrata)
		formData.append('ivaUscita',ivaUscita)
		formData.append('dataOperazione',dataOperazione)
		formData.append('dataValuta',dataValuta)
		formData.append('entrata',entrata)
		formData.append('uscita',uscita)
		formData.append('descrizione',descrizione?descrizione:'')
		formData.append('banca',banca)
		formData.append('notInTotal',notInTotal)
		formData.append('tipocosto',tipocosto)
        formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('palestra',palestra.id);
		formData.append('lic','000');
      	axios.post(serverInfo.namesec+'/conto/salvaMovimentoRest/'+id, 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			  },
			},
      )
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'bottom-end',
			  showConfirmButton: false,
			  timer: 10000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})

          setvisible(false);
        });
	}
	
	function eliminaMovimento() {
		const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('palestra',palestra.id);
		formData.append('lic','000');
      	axios.post(serverInfo.namesec+'/conto/eliminaMovimento/'+id, 
      		formData,
			  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			  },
			},)
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'bottom-end',
			  showConfirmButton: false,
			  timer: 10000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          props.refresh();
        });
    }

		var style1={
				color:"white"
		}
		var style2={
				textAlign:"right"
		}

		const numberStyleUscite={
			textAlign:'right', padding:'2px', margin:'0px',border: '2px solid red',	borderRadius: '4px'
		}
		const numberStyleEntrate={
			textAlign:'right', padding:'2px', margin:'0px',border: '2px solid green',	borderRadius: '4px'
		}
	    return (
				    <div className={entrata>0?"row table-success":uscita<=-150?"row table-danger":"row"} style={tipoMovimento1===null||tipoMovimento1===''?{"backgroundColor":"gray"}:null}>
						<div className="col-12">
						<div className="row">
							<div className="col form-group" data-label="Data Operazione">
								<label>Data Operazione</label>
								<input type="date" className="form-control" name="dataOperazione" required={true} value={dataOperazione} onChange={(e)=> setdataOperazione(e.target.value)} />
							<br />
								<label>Data Valuta</label>
								<input type="date" className="form-control" name="dataValuta" required={true} value={dataValuta} onChange={(e)=> setdataValuta(e.target.value)} />
							</div>
							<div className="d-lg-none d-xl-none w-100"></div>
							
							<div className="col form-group" data-label="Entrata">
								<label>Entrata</label>
								<input type="number" className="form-control"  step=".01" style={numberStyleEntrate} name="entrata" required={true} value={entrata>0?entrata:0} onChange={(e)=> setentrata(e.target.value)} />
								
							<br/>
								<label>di cui Iva a debito</label>
								<input type="number" className="form-control" step=".01" style={numberStyleUscite} name="ivaEntrata" value={ivaEntrata} onChange={(e)=> setivaEntrata(e.target.value)} />
							</div>
							<div className="d-lg-none d-xl-none w-100"></div>
							<div className="col form-group" data-label="Uscita">
							<label>Uscita</label>
								<input type="number" className="form-control" step=".01" style={numberStyleUscite} name="uscita" required={true} value={uscita<0?uscita:0} onChange={(e)=> setuscita(e.target.value)} />
							<br/>
							<label>di cui Iva a credito</label>
								<input type="number" className="form-control" step=".01" style={numberStyleEntrate} name="ivaUscita" value={ivaUscita} onChange={(e)=> setivaUscita(e.target.value)} />
							</div>
							<div className="d-lg-none d-xl-none w-100"></div>
							<div className="col form-group" data-label="Descrizione">
							<label>Descrizione</label>
								<textarea name="descrizione" className="form-control" value={descrizione} rows={4} onChange={(e)=> setdescrizione(e.target.value)}/>
							</div>
						</div>
						<div className="row">
							<label>Note
									<input type="text" name="note" className="form-control" value={note} onChange={(e)=> setnote(e.target.value)} />
								</label>
								<div className="col-lg-3 col-md-3 col-xs-6 col-sm-6 form-group" data-label="Causale">
									<label>Causale</label> {causale}
								</div>
								<div className="col-lg-3 col-md-3 col-xs-6 col-sm-6 form-groupl" data-label="Banca">
									<label>Banca</label>
									<ListaBancheSelect banca={banca} update={(e)=> setbanca(e.target.value)}/>
								</div>
								<div className="d-lg-none d-xl-none w-100"></div>
								<div className="col form-group" data-label="Tipologia">
								{
								entrata>0?
									<SelectTipologieEntrata update={updateTipo} selected={tipoMovimento1} />
								:
									<SelectTipologieUscita update={updateTipo} selected={tipoMovimento1} />
								}
								</div>
						</div>
						<div className="row">
							<div className="col">
							<label>Tipologia di Costo</label>
								<TipologiaCostoSelect selected={tipocosto} update={(e)=> settipocosto(e.target.value)}/>
							</div>
							<div className="col form-group" data-label="Protocollo">
							{
							protocolli&&Array.isArray(protocolli)&&protocolli.map.length>0?
								(protocolli.map(item =>(
									
									<div key={item.id}>
										<button onClick={() => showprotocol(item)}>{item.oggetto}</button>
										<a type="button" className="btn btn-danger" onClick={() => handleEliminaProtocollo(item.id)} id={id+"__"+item.id}>
											<i className="fa fa-trash"></i>
										</a>
										{showproto&&<DettaglioProtocollo />}
									</div>
								)
								)
								)
							
							:""
							}
							
							<ListaProtocolliSelect updateProto={updateProto} />
							<a type="button" className="btn btn-danger" onClick={handleInsertProtocollo}>Associa protocollo</a>
							</div>
							<div className="col">
							{notInTotal&&notInTotal===true?"NON CALCOLATO NEL TOTALE":""}
							<div >
							<label>Calcola nel totale
							SI <input type="checkbox" name="notInTotal" value="0" checked={!notInTotal} onChange={updateNotInTotal}/>
							</label>
							</div>
							</div>
							<div className="col" data-label="Salva">
									<a type="button" className="btn btn-primary" onClick={handleSubmit} style={style1}>
									<FontAwesomeIcon icon={faSave} />
									</a>
								<a type="button" className="btn btn-danger" onClick={(e)=>{if (window.confirm('Are you sure you wish to delete this item?')) eliminaMovimento(e)}}>
								<FontAwesomeIcon icon={faTrash} />
								</a>
							</div>
						</div>
						<div className="row">
							<div className="col-12"><hr/></div>
						</div>
						</div>
						</div>
				
	    );
}