import React from "react";
import '../util/config'
import { useState } from "react";
import { useSelector } from "react-redux";
import { cercaprotocolligenerico } from "../DAO/protocolliDAO";
import { useEffect } from "react";

let timeout=0;
export default function ListaProtocolliSelect({updateProto}) {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const tk = useSelector(state => state.gestoreUtente.token);

    const [cercaproto,setcercaproto] = useState('')
    const [protocolli, setprotocolli] =useState([])

    function cercaprotocolli(protodacercare){
        async function cercaprot(){
            const result=await cercaprotocolligenerico(palestra.palestra.id, protodacercare)
            setprotocolli(result)
        }
        if (protodacercare&&protodacercare.length>2){
            cercaprot()
        }
    }

    useEffect(() => {
        clearTimeout(timeout); 
        timeout = setTimeout(() => {
            cercaprotocolli(cercaproto)
        },1000)    
    },[cercaproto])

    function aggiornaSelezionato(e){
        updateProto(protocolli[e.target.selectedIndex-1].id);
    }

      return (
          <div>
            Protocolli 
            <input type="text" placeholder="cerca protocollo da associare" 
            name="cercaproto" onChange={(e) => {setcercaproto(e.target.value); }} />

            {protocolli&&Array.isArray(protocolli)&&protocolli.length>0&&  <select onChange={aggiornaSelezionato} name="protocollo">
                  <option key={-1} value="">--seleziona un protocollo--</option>
              {
                  protocolli&&Array.isArray(protocolli)?protocolli.map((item,index) =>(
                          <option key={index} value={item.id}>
                              {item.numero}/{item.anno} -{item.oggetto}
                              </option>
                          )
                  )
                  :null
              }
              </select>}
              </div>
      );
  }