import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import ListaMovimenti from "./listaMovimenti";
import ListaBancheSelect from "../banche/listaBancheSelect";
import SelectTipoMovimento from "../tipologie/selectTipologieMovimento";
import ListaCausaliSelect from '../causali/listaCausaliSelect'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch,faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import Menu from "../toolbar/menu";
import NuovoMovimentoInput from "./NuovoMovimentoInput";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function FiltriMovimenti () {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [daAnnoMese, setdaAnnoMese] = useState(moment().format("YYYY-MM-DD"));
	const [aAnnoMese, setaAnnoMese]= useState(moment().format("YYYY-MM-DD"));
	const [daAnnoMeseValuta,setdaAnnoMeseValuta]= useState('');
	const [aAnnoMeseValuta,setaAnnoMeseValuta]= useState('');
	const [importoEntrata,setimportoEntrata]= useState('');
	const [importoUscita, setimportoUscita]= useState('');
	const [descrizioneMovimento, setdescrizioneMovimento]= useState('');
	const [causale, setcausale]= useState('');
	const [banca,setbanca]= useState('');
	const [tipo, settipo]= useState('')
	const [movCarta, setmovCarta]= useState('');
	const [movimenti, setmovimenti]= useState([]);
	const [protocolli, setprotocolli]= useState([]);
	const [tipocosto, settipocosto]= useState('');
	const [popupVisible,setpopupVisible]= useState(false);
	   
	useEffect(() =>{
		if (palestra&&palestra.palestra){
		const formData = new FormData();
        formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.palestra.id);
        axios.post(serverInfo.namesec+"/proto/getProtocolli",formData,
		{
		headers: {
		  Accept: 'application/json',
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': 'POST',
		  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		  'Access-Control-Allow-Credentials': 'true',
		  'Authorization' :'Bearer '+token
		},
	  },)
        .then(response=>{
            setprotocolli(response.data);
        }).catch(err => {
			alert(err.response.data.message);
		  });
		}
	  },[palestra,email]);

	function togglePopup(){
		setpopupVisible(!popupVisible)
	}

	function handleSubmit(event){
		event.preventDefault();

		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('daAnnoMese', daAnnoMese);
		formData.append('aAnnoMese', aAnnoMese);
		formData.append('daAnnoMeseValuta', daAnnoMeseValuta);
		formData.append('aAnnoMeseValuta', aAnnoMeseValuta);
        formData.append('importoEntrata', importoEntrata);
		formData.append('importoUscita', importoUscita);
        formData.append('descrizioneMovimento', descrizioneMovimento);
        formData.append('causale', causale?causale:'');
        formData.append('banca', banca);
        formData.append('tipoMovimento', tipo);
        formData.append('movCarta', movCarta);
		formData.append('tipocosto', tipocosto);
		formData.append('palestra',palestra.palestra.id);
      axios.post(serverInfo.namesec+'/conto/VisualizzaMovimenti', formData,
	  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token
      },
    },)
        .then((result) => {
			console.log(result.data)
			setmovimenti([]);
        	setmovimenti(result.data);
        });
	  }
	
	    return (
			<span>
				{email?
			<div className="container">
				
			<div className="row">
				<div className="col">
					<label>Data operazione Da:
					<input type="date" id="start_date1" max={aAnnoMese} name="daAnnoMese" className="form-control" value={daAnnoMese} onChange={(e) =>setdaAnnoMese(e.target.value)}/></label> 
				</div>
				<div className="col">
					<label>Data operazione A:
					<input type="date" id="end_date1" min={daAnnoMese} name="aAnnoMese" className="form-control" value={aAnnoMese} onChange={(e) =>setaAnnoMese(e.target.value)}/></label> 
				</div>
				<div className="w-100 d-block d-sm-none"></div>
				<div className="col">
					<label>Data valuta Da:
					<input type="date" id="start_date2" max={aAnnoMese} name="daAnnoMeseValuta" className="form-control" value={daAnnoMeseValuta} onChange={(e) =>setdaAnnoMeseValuta(e.target.value)}/></label> 
					</div>
					<div className="col">
					<label>Data valuta A:
					<input type="date" id="end_date2" min={daAnnoMeseValuta} name="aAnnoMese" className="form-control" value={aAnnoMeseValuta} onChange={(e) =>setaAnnoMeseValuta(e.target.value)}/></label> 
				</div>
				<div className="w-100"></div>
				<div className="col">
				<label>Entrata</label>
					<input type="text" size="6" maxlength="6" name="importoEntrata" value={importoEntrata} className="form-control" onChange={(e) =>setimportoEntrata(e.target.value)}/>
				</div>
				<div className="col">
					<label>Uscita</label>
					<input type="text" size="6" maxlength="6" name="importoUscita" value={importoUscita} className="form-control" onChange={(e) =>setimportoUscita(e.target.value)}/>
				</div>
				<div className="w-100 d-block d-sm-none"></div>
				<div className="col">
					<label>Descrizione</label>
					<input type="text" size="20" maxlength="20" name="descrizioneMovimento" value={descrizioneMovimento} className="form-control" onChange={(e) =>setdescrizioneMovimento(e.target.value)}/>
				</div>
				<div className="col">
					<ListaCausaliSelect causale={causale} update={(e) =>setcausale(e.target.value)}/>
				</div>
				<div className="w-100 d-block d-sm-none"></div>
				<div className="col">
					<ListaBancheSelect banca={banca} update={(e) =>setbanca(e.target.value)} />
				</div>
				<div className="w-100 d-none d-sm-block d-md-none"></div>
				<div className="col">
					<SelectTipoMovimento selected={tipo} update={(e) =>settipo(e.target.value)}/>
				</div>
				<div className="col">
					<label>Visualizza movimenti carta
						<input type="checkbox" name="movCarta" value="1" className="form-control" checked={movCarta} 
						onClick={(e) => setmovCarta(e.target.checked)}/>
					</label>
				</div>
				
			</div>
			<div  className="row">
				<a type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>
					<FontAwesomeIcon icon={faSearch} />
				</a>
				<a type="button" className="btn btn-info btn-lg" onClick={togglePopup}>
						<FontAwesomeIcon icon={faPlusCircle} />
					</a>
					{popupVisible?
				<div className="row">
				<NuovoMovimentoInput togglePopup={togglePopup}/>
				</div>
				:null}
			</div>
				<ListaMovimenti movimenti={movimenti} protocolli={protocolli}/>
			</div>
			:<div>E' necessario autienticarsi</div>}
			</span>
	    );
}