import React, { useState } from "react";
import axios from 'axios';
import SelectTipologieUscita from '../tipologie/SelectTipologieUscita'
import ListaBancheSelect from '../banche/listaBancheSelect';
import ListaCausaliSelect from '../causali/listaCausaliSelect';
import SelectTipologieEntrata from '../tipologie/selectTipologieEntrata';
import '../util/config'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function NuovoMovimentoInput (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const [dataOperazione, setdataOperazione] = useState();	
	const [dataValuta, setdataValuta] = useState();
	const [entrata, setentrata] = useState();
	const [ivaEntrata, setivaEntrata] = useState();
	const [uscita, setuscita] = useState();	
	const [ivaUscita, setivaUscita] = useState();
	const [descrizione, setdescrizione] = useState();
	const [causale, setcausale] = useState();
	const [nomeBanca, setnomeBanca] = useState();
	const [tipoMovimento1, settipoMovimento1] = useState();	
	const [notInTotal, setnotInTotal] = useState();
	const [idcaricamento, setidcaricamento] = useState();
	const [note, setnote] = useState();
	const [listaTipoUscita, setlistaTipoUscita] = useState();
	const [listaTipoEntrata, setlistaTipoEntrata] = useState();
	const [visible, setvisible] = useState();
	const [tipocosto, settipocosto] = useState();
	const [messaggio, setmessaggio] = useState('');

	function handleSubmit(event) {
		event.preventDefault();
	   
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('palestra',palestra.palestra.id);
		formData.append('lic','000');
	    formData.append('dataOperazione', dataOperazione);
        formData.append('dataValuta', dataValuta);
        formData.append('entrata', entrata);
		formData.append('uscita', uscita);
		formData.append('ivaEntrata', ivaEntrata);
        formData.append('ivaUscita', ivaUscita);
        formData.append('descrizione', descrizione);
        formData.append('causale', causale?causale:'');
        formData.append('nomeBanca', nomeBanca);
        formData.append('tipoMovimento1', tipoMovimento1);
        formData.append('notInTotal', notInTotal);
        formData.append('idcaricamento', idcaricamento);
        formData.append('note', note?note:'');
        formData.append('listaTipoUscita', listaTipoUscita);
        formData.append('listaTipoEntrata', listaTipoEntrata);
		formData.append('tipocosto', tipocosto);
		
      axios.post(serverInfo.namesec+'/conto/insMovimentoRest', formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	}, )
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'center-start',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          //this.props.refresh();
          setvisible(false);
        });
    }

	    return (
						<div className="modal-header">
						<i className="fas fa-plus-circle"></i>
							<h4 className="modal-title">Nuovo Movimento</h4>
						<form method="POST" onSubmit={handleSubmit}>
							<div className="modal-body">
								<div className={entrata>0?"row table-success":uscita<0?"row table-danger":"row "}>
									<div className="col">
										<label>Data Operazione</label>
										<input className="form-control" required={true} name="dataOperazione" type="date" placeholder="yyyy-mm-dd" maxLength="10" value={dataOperazione} onChange={(e) => setdataOperazione(e.target.value)} />
									</div>
									<div className="col">
										<label>Data Valuta</label>
										<input className="form-control" required={true} name="dataValuta" type="date" placeholder="yyyy-mm-dd" maxLength="10" value={dataValuta} onChange={(e) => setdataValuta(e.target.value)} />
									</div>
									<div className="w-100"></div>
									<div className="col">
										<label>Entrata</label>
										<input className="form-control" step=".01" name="entrata" type="number" min="0" size="10" value={entrata} onChange={(e) => setentrata(e.target.value)} />
									</div>
									<div className="col">
										<label>di cui Iva</label>
										<input className="form-control" step=".01" name="ivaEntrata" type="number" min="0" size="10" value={ivaEntrata} onChange={(e) => setivaEntrata(e.target.value)} />
									</div>
									<div className="w-100"></div>
									<div className="col">
										<label>Uscita</label>
										<input className="form-control" step=".01" name="uscita" type="number" min="0" size="10" value={uscita} onChange={(e) => setuscita(e.target.value)} />
									</div>
									<div className="col">
										<label>di cui Iva</label>
										<input className="form-control" step=".01" name="ivaUscita" type="number" min="0" size="10" value={ivaUscita} onChange={(e) => setivaUscita(e.target.value)} />
									</div>
									<div className="w-100"></div>
									<div className="col">
									<label>Descrizione</label>
									<textarea className="form-control" required={true} name="descrizione" value={descrizione} rows={5} onChange={(e) => setdescrizione(e.target.value)} />
									</div>
									<div className="w-100"></div>
									<div className="col">
										<ListaCausaliSelect causale={causale} update={(e) => setcausale(e.target.value)}/>
									</div>
									<div className="col">
									
										<ListaBancheSelect banca={nomeBanca} update={(e) => setnomeBanca(e.target.value)} />
									</div>
									<div className="w-100"></div>
									<div className="col">
									<label>Note</label>
										<input className="form-control" type="text" name="note" size="20" maxLength="2000" value={note} onChange={(e) => setnote(e.target.value)} />
										</div>
										<div className="w-100"></div>
									<div className="form-group">
									{
									entrata>0?
										<SelectTipologieEntrata update={settipoMovimento1} selected={tipoMovimento1}  />
									:
										<SelectTipologieUscita update={settipoMovimento1} selected={tipoMovimento1} />
									}
									</div>
									<div className="form-group">
										<label className="control-label">Tipologia di Costo</label>
										<select className="form-control"  name="tipocosto" onChange={(e) => setdataOperazione(e.target.value)} value={tipocosto}>
											<option value="fisso">Fisso</option>
											<option value="variabile">Variabile</option>
											<option value="ammBeniMobiliStrumentali">Beni mobili strumentali</option>
											<option value="ammBeniImmobiliStrumentali">Beni immobili strumentali</option>
											<option value="ammBeniImmaterialiStrumentali">Beni immateriali strumentali</option>
										</select>
									</div>
									<div className="form-group">
										<label className="control-label">Non nel totale
										<input className="form-control"  type="checkbox" onChange={() => (e) => setnotInTotal(e.target.checked)} checked={notInTotal} />
										</label>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={props.togglePopup} >Close</button>
								<button type="submit" className="btn btn-primary">Save changes</button>
							</div>
						</form>
					</div>
	    );
}