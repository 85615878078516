import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class NuovaBanca extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
			id: 0,
			nome: "",
			descrizione: "",
	    	visible: false
	    }
		this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showBanca=this.showBanca.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
		handleSubmit(event) {
		  event.preventDefault();
	    const { id,descrizione,nome} = this.state;
	    const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	    formData.append('id', id);
	    formData.append('descrizione',descrizione);
		formData.append('nome', nome);
		
      axios.post(serverInfo.namesec+'/conto/inserisciBanca', 
      		formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+this.props.token
				},
			  },
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
			this.props.refresh(new Date().getTime());
          this.setState({ visible: false });
        });
    }
	

	showBanca(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		return (
			<span>
				<a type="button" className="btn btn-info btn-lg" onClick={this.showBanca}>
						<FontAwesomeIcon icon={faPlusCircle} />
					</a>
						{this.state.visible&&
						<span>
							<h5 className="modal-title" id="nuovabancaLabel">Inserimento Nuova Banca</h5>
					<div className="modal-body">

						
						<form method="POST">
							<div className="col">
								<div className="row">
								<div className="form-group">
										<label>Nome</label>
										<input name="nome" className="form-control" type="text" maxLength="50" value={this.state.nome} onChange={this.update} />
									</div>
									<div className="form-group">
										<label>Descrizione</label>
										<input maxLength="50" className="form-control" name="descrizione" type="text" value={this.state.descrizione} onChange={this.update} />
									</div>	
								</div>
							</div>
						</form>
						</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" onClick={this.showBanca} >Close</button>
						<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Save changes</button>
					</div>
					</span>}
		</span>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata,
		token: state.gestoreUtente.token
	}
}

export default connect(mapStateToProps, null)(NuovaBanca)