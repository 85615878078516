import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import store from './STORE/store';
import LoadMainThings from './util/LoadMainThings';
import { Provider } from 'react-redux';
import AppRoute from './AppRoute';
import PalestreAssociateSelect from './palestre/PalestreAssociateSelect';

var elem = document.getElementById("annotatio-conto");
if (elem){
const email=elem.getAttribute("email");
if (email){
  localStorage.setItem('email',email);
}
const key=elem.getAttribute("key");
localStorage.setItem('key',key);

const tkn=elem.getAttribute("t");
window.$t=tkn;

ReactDOM.render(
  <Provider store={store}>
        <AppRoute email={email} showtoolbar={true} intestazione={false} t={tkn} />
      </Provider>,
  elem
);
}

/************+ */
//LISTA PALESTRE ASSOCIATE
/************ */
var attivitaassociate=document.getElementById("attivitaassociate");
if (attivitaassociate){
  const email=attivitaassociate.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
  }  
  const key=attivitaassociate.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=attivitaassociate.getAttribute("linkannotatio");

  ReactDOM.render(
      <Provider store={store}>
        <LoadMainThings email={email} />
        <PalestreAssociateSelect email={email} link={linkannotatio} />
      </Provider>
      ,
      attivitaassociate
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
