 import React from "react";
import axios from 'axios';
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import Swal from "sweetalert2";
import '../util/config'
import { useSelector } from "react-redux";
import { useState } from "react";
import serverInfo from "../util/globals";
import { useEffect } from "react";
import {navigate} from 'hookrouter'

export default function DettaglioProtocollo() {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
	const protoselezionato = useSelector(state => state.gestoreProtocolli.protocolloselezionato);

	const [id, setid] = useState(protoselezionato.id);
	const [tipo, settipo] = useState(protoselezionato.tipo);
	const [descrizione, setdescrizione] = useState(protoselezionato.descrizione);
	const [idtipomovimento, setidtipomovimento] = useState(protoselezionato.idtipomovimento);
	const [oggetto, setoggetto] = useState(protoselezionato.oggetto);
	const [datascadenza, setdatascadenza] = useState(protoselezionato.datascadenza);
	const [dataarrivo, setdataarrivo] = useState(protoselezionato.dataarrivo);
	const [numero, setnumero] = useState(protoselezionato.numero);
	const [anno, setanno] = useState(protoselezionato.anno);
	const [visible, setvisible] = useState(false);
	const [file, setfile] = useState(null);
	const [files, setfiles] = useState([]);
	const [messaggio, setmessaggio] = useState('');

	function handleMostraFile(){
	    const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/proto/caricaFilesProtocolloRest/'+id, 
      		formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },
      )
        .then((result) => {
        setfiles(result.data);
        }).catch(err => {
			alert("Caricamento file: "+err.response.data.message);
		});
	  }

	useEffect(() =>{
		handleMostraFile();
		// document.getElementById('imgId').src = null;
	},[protoselezionato.id])

	function updateTipo(tipoSelezionato) {
		var tipoMovimento1 = idtipomovimento
		tipoMovimento1 = tipoSelezionato;
		setidtipomovimento(tipoMovimento1);
		setvisible(true)
	  }



		var style1={
				color:"black"
		}
	    return (
      <div className="container-fluid">
		
		<form method="POST">
			<div className="row">
				<div className="col">
					<div className="form-group">
						<label>Data arrivo</label><br/>
						{dataarrivo}
					</div>
				</div>
				<div className="col">
					<div className="form-group">
							<label>Tipo (E/U)</label><br/>
							{tipo==='E'?'ENTRATA':'USCITA'}
						</div>
				</div>
			</div>
            <div className="row">
                <div className="col">
					<div className="form-group">
						<label>Oggetto</label><br/>
						{oggetto}
					</div>
				</div>
				<div className="col">
					<div className="form-group">
					<label>Tipo</label><br/>
							{idtipomovimento}
						</div>
				</div>
				<div className="col">
						<div className="form-group">
							<label>Data scadenza</label><br/>
							{datascadenza}
						</div>
				</div>
			</div>
			<div className="row">
				<div className="form-group">
					<label>Descrizione</label><br/>
					{descrizione}
				</div>
			</div>
		</form>
        <div className="row">
				{files&&Array.isArray(files)?
					(files.map(item =>(
						<div key={item.id}>
							<a href={serverInfo.namesec+"/wp/scaricaFile/"+item.id}>{item.nomeOriginale} ({item.dimensione} K)</a> | 
						</div>
					)
					)
					)
				
				:""
				}
		</div>
    </div>
	    );
	}