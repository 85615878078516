import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import Banca from "./banca";
import NuovaBanca from "./nuovaBanca"; 

import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function ListaBanche() {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const tkn = useSelector(state => state.gestoreUtente.token);

  const [banche, setbanche] = useState([])
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  
  useEffect(() =>{
	if (palestra&&palestra.palestra){
		console.log("lista bacnhe: window.$t "+window.$t)
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getBanche",formData,
	  {
		headers: {
		  Accept: 'application/json',
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': 'POST',
		  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		  'Access-Control-Allow-Credentials': 'true',
		  'Authorization' :'Bearer '+tkn
		},
	  },)
	  .then(response=>{
		 setbanche(response.data);
		  console.log(response);
	  })
	  .catch(err => {
		alert("lista banche: "+err.response.data.message);
	});
}
  },[palestra, email, keytorefresh])

  function refresh(){
	setkeytorefresh(new Date().getTime())
  }


    return (
    		<div key={keytorefresh} className="container">
				<NuovaBanca refresh={refresh}/>

				<div className="row">
    		{
				banche&&Array.isArray(banche)&&banche.length>0?
    			banche.map(item =>(
    					<Banca key={item.id} selected={item}
    					onUpdate={item.id} refresh={setkeytorefresh}  />
    					)
				)
				:"Nessuna banca configurata"
    		}
    		</div>
    		</div>
    );
}