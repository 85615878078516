import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import Regola from "./regola";
import NuovaRegola from "./nuovaRegola";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function ListaRegole (){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const tk = useSelector(state => state.gestoreUtente.token);

    const [listaRegole, setlistaRegole]=useState([])
	const [popupVisible, setpopupVisible]=useState(false)
	const [keytorefresh, setkeytorefresh]=useState(new Date().getTime())
   
function togglePopup(){
  setpopupVisible(!popupVisible)
}
  
  useEffect(() =>{
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',email);
	formData.append('palestra',palestra.palestra.id);
	formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getRegole", formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+tk
	  },
	  },)
	  .then(response=>{
		  setlistaRegole(response.data);
		  console.log(response);
	  }).catch(err => {
		alert(err.response.data.message);
	  });
  },[palestra, email, keytorefresh]);

    return (
    		<div key={keytorefresh} className="container">
				{popupVisible?
				<div className="row">
				<NuovaRegola refresh={setkeytorefresh} togglePopup={togglePopup}/>
				</div>
				:null}
				<button type="button" className="btn btn-primary" onClick={togglePopup}>
				<FontAwesomeIcon icon={faPlusCircle} />
				</button>
    		{
    			listaRegole.map(item =>(
    					<Regola key={item.id} selected={item}
    					onUpdate={item.id} refresh={setkeytorefresh}  />
    					)
    			)
    		}
    		
    		</div>
    );
}