import {configureStore} from '@reduxjs/toolkit';
import gestoreOrdiniReducer from './reducers/gestoreOrdini';
import gestoreUtentiReducer from './reducers/gestoreUtente';
import gestoreTipiProdottoReducer from './reducers/gestoreTipiProdotto';
import gestoreProtocolliReducer from './reducers/gestoreProtocolli';


export default configureStore({
  reducer: {
    gestoreOrdini: gestoreOrdiniReducer,
    gestoreUtente: gestoreUtentiReducer,
    gestoreTipiProdotto: gestoreTipiProdottoReducer,
    gestoreProtocolli: gestoreProtocolliReducer,
  },
});
